/* global rp$, window */

require('public/css/language-selection.scss');

var THE_SCRIPT = function () {

	const $onReady = require('public/js/lib/on-ready');

	const languageSelection = '.language-selector';

	$onReady({
		rp$,
		window,
		label: 'language_selection',
		fn: function () {
			rp$(languageSelection).on('change', function (evt) {
				evt.preventDefault();
				$ajax({
					method: 'POST',
					dataType: 'json',
					url: '/ajax/locale/update',
					data: {
						locale: evt.target.value,
					},
					success: (response, status/*, xhr*/) => {
						if (status === 'success') {
							window.location.reload();
						}
					}
				});
			});
		},
	});
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
